import * as React from "react"
import { graphql } from "gatsby"
import HeroImg from "../images/production-set-3.png"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <div className="container mx-auto text-white">
          <div className="p-5 lg:py-10">
            <div className="flex flex-col justify-between">
              <div className="container mx-auto">

              <div className="flex items-center justify-between my-5 md:my-0 md:py-10">
                <div className="grid grid-cols-3 gap-5">
                  <div className="">
                    <h1 className="text-2xl md:text-4xl font-semibold">
                      About
                    </h1>
                  </div>
                  <div className="col-span-2">
                  <p className="leading-5 text-sm md:text-lg">CREWZIP is a dynamic production job matching platform. We specialize in seamlessly connecting top talent with premier production opportunities, ensuring every project reaches its creative potential.</p>
                  </div>
                </div>
              </div>

              <div className="py-5">

              <div className="relative mb-10">
                <img src={HeroImg} className="w-full" />
                <div class="h-60 lg:h-96 bg-gradient-to-t from-gray-900 absolute w-full bottom-0" />
              </div>
                  <div className="text-sm md:text-lg md:w-9/12 mx-auto">
                    <p className="my-6">We revolutionize the way production jobs are found and filled. Our platform is designed to be the fastest and most efficient conduit between production companies and top-notch talent. For those seeking exceptional production roles, CREWZIP is your gateway to success.</p>
                    <p className="my-6">We understand the pulse of the media production industry. That's why we've become the go-to destination for freelancers and companies alike. Our platform doesn’t just connect people; it fosters the creation of dynamic, creative teams that are the backbone of unforgettable productions.</p>
                    <p className="my-6">The strength of CREWZIP lies in our robust network, powered by one of the internet's largest TV and Film Credits databases. This connection not only sustains but also enhances our position as a leading digital recruitment tool. We're not just a platform; we're a launching pad for your next big project or career move.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="About CREWZIP" />

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
